import { lazy } from 'react';

import ErrorPage from 'konect-admin/components/ErrorPage';
import ExpiredPage from 'konect-admin/components/ExpiredPage';
import BigcommerceRedirect from 'pages/bigCommerce/redirect';
import BigCommerce from 'pages/bigCommerce';
import SfdcSuccess from 'pages/sfdc/success';
import MiddlewareDashboard from 'pages/middleware/index';
const Sfdc = lazy(() => import('pages/sfdc'));
const SfdcActivate = lazy(() => import('pages/sfdc/activate'));
const SfdcConfiguration = lazy(() => import('pages/sfdc/configuration'));
const SfdcDashboard = lazy(() => import('pages/sfdc/dashboard'));
const DwdrumsDashboard = lazy(() => import('pages/dwdrums/dashboard'));
const DmDrums = lazy(() => import('pages/dwdrums'));
const ShopifyDashboard = lazy(() => import('pages/shopifyConfig/dashboard'));
const ShopifyConfig = lazy(() => import('pages/shopifyConfig'));
const ShopifyActivate = lazy(() => import('pages/shopifyConfig/activate'));
const ShopifyRedirect = lazy(() => import('pages/shopifyConfig/redirect'));
const ShopifySuccess = lazy(() => import('pages/shopifyConfig/success'));
const Invalid = lazy(() => import('pages/shopifyConfig/invalid'));
const WooCommerceActivate = lazy(() => import('pages/woocommerce/activate'));
const WooCommerce = lazy(() => import('pages/woocommerce'));
const WooCommerceSuccess = lazy(() => import('pages/woocommerce/success'));
const Scheels = lazy(() => import('pages/scheels'));
const BigCommerceActivate = lazy(() => import('pages/bigCommerce/activate'));
const BigCommerceProcessing = lazy(() => import('pages/bigCommerce/processing'));
// const BigcommerceRedirect = lazy(() => import('pages/bigCommerce/redirect'));
const BigcommerceDashboard = lazy(() => import('pages/bigCommerce/dashboard'));
const ShopwareActivate = lazy(() => import('pages/shopWare/activate'));
const ShopWare = lazy(() => import('pages/shopWare'));
const ShopWareSuccess = lazy(() => import('pages/shopWare/success'));

// const SpreadSheet = lazy(() => import('pages/spreadsheet'));

export default [
  {
    path: '/login', // This is the landing URL if API throw 401
    component: ExpiredPage,
  },
  {
    path: '/error',
    component: ErrorPage,
  },
  // {
  //   path: '/plugins/edit',
  //   exact: true,
  //   private: true,
  //   component: SpreadSheet,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Manage' }, { label: 'Plugins', path: '/plugins' }],
  //   heading: {
  //     title: 'Plugin Details',
  //     subTitle: 'View and manage plugin details',
  //   },
  // },
  {
    path: '/sfdc/configs/dashboard',
    // exact: true,
    private: true,
    component: SfdcDashboard,
  },
  {
    path: '/sfdc/configs/configuration',
    // exact: true,
    private: true,
    component: SfdcConfiguration,
  },
  {
    path: '/sfdc/app_configuration_iframe',
    // exact: true,
    private: true,
    component: Sfdc,
  },
  {
    path: '/sfdc/activate',
    // exact: true,
    private: true,
    component: SfdcActivate,
  },
  {
    path: '/sfdc/success',
    // exact: true,
    private: true,
    component: SfdcSuccess,
  },
  {
    path: '/dwdrums/app_configuration_iframe',
    // exact: true,
    private: true,
    component: DmDrums,
  },
  {
    path: '/shopify/configs/dashboard',
    // exact: true,
    private: true,
    component: ShopifyDashboard,
  },
  {
    path: '/shopify',
    // exact: true,
    private: true,
    component: ShopifyConfig,
  },
  {
    path: '/shopify/app_configuration_iframe',
    // exact: true,
    private: true,
    component: ShopifyActivate,
    layout: 'DashboardLayout',
  },
  {
    path: '/shopify/app-redirect',
    // exact: true,
    private: true,
    component: ShopifyRedirect,
    layout: 'DashboardLayout',
  },
  {
    path: '/shopify/success',
    // exact: true,
    private: true,
    component: ShopifySuccess,
    layout: 'DashboardLayout',
  },
  {
    path: '/shopify/invalid-request',
    // exact: true,
    private: true,
    component: Invalid,
    layout: 'DashboardLayout',
  },
  {
    path: '/dwdrums/configs/dashboard',
    // exact: true,
    private: true,
    component: DwdrumsDashboard,
  },
  {
    path: '/middleware/profile/dashboard',
    // exact: true,
    private: false,
    component: MiddlewareDashboard,
  },
  {
    path: '/woocommerce/app_configuration_iframe',
    // exact: true,
    private: true,
    component: WooCommerceActivate,
    layout: 'DashboardLayout',
  },
  {
    path: '/woocommerce',
    // exact: true,
    private: true,
    component: WooCommerce,
  },
  {
    path: '/woocommerce/success',
    // exact: true,
    private: true,
    component: WooCommerceSuccess,
  },
  {
    path: '/scheelsFTP/app_configuration_iframe',
    // exact: true,
    private: true,
    component: Scheels,
    layout: 'DashboardLayout',
  },
  {
    path: '/bigcommerce',
    // exact: true,
    private: true,
    component: BigCommerce,
    layout: 'DashboardLayout',
  },
  {
    path: '/bigCommerce/app-redirect',
    // exact: true,
    // private: true,
    component: BigcommerceRedirect,
    // layout: 'DashboardLayout',
  },
  {
    path: '/bigcommerce/app_configuration_iframe',
    // exact: true,
    private: true,
    component: BigCommerceActivate,
    layout: 'DashboardLayout',
  },
  {
    path: '/bigcommerce/processing',
    // exact: true,
    private: true,
    component: BigCommerceProcessing,
    layout: 'DashboardLayout',
  },
  {
    path: '/bigcommerce/dashboard',
    // exact: true,
    private: true,
    component: BigcommerceDashboard,
  },
  {
    path: '/shopware',
    // exact: true,
    private: true,
    component: ShopWare,
  },
  {
    path: '/shopware/app_configuration_iframe',
    // exact: true,
    private: true,
    component: ShopwareActivate,
    layout: 'DashboardLayout',
  },
  {
    path: '/shopware/success',
    // exact: true,
    private: true,
    component: ShopWareSuccess,
    layout: 'DashboardLayout',
  },
];
